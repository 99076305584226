<template>
    <div>
      <v-card :loading="loading" :disabled="loading" >
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        >
          <span class="text-h6">公開設定</span>
          <v-btn icon @click="slide=true;"><v-icon>{{icons.informationOutline}}</v-icon></v-btn>
        </v-toolbar>
      
        <v-card-text >
          <div style="margin:0 auto 0 auto; width:240px;">
            <v-btn-toggle v-model="selected"
              mandatory
              color="narekomu"
            >
              <v-btn value="unlisted" >
                非公開
              </v-btn>

              <v-btn value="private" :disabled="this.$store.state.user_model.userData.status < '2' ">
                限定公開
              </v-btn>

              <v-btn value="public"  :disabled="this.$store.state.user_model.userData.status < '2'">
                全体公開
              </v-btn>

            </v-btn-toggle>
           </div>

           
           <v-list :class="($vuetify.breakpoint.xs) ? 'mx-1 text-center body-1' :'mx-4 text-center body-1'" v-if="this.$store.state.user_model.userData.status < '2'"  >
             公開設定を行うには契約プランが <span class="font-weight-bold" style="color:#4ECDC4;">EnterPrize</span> の必要があります
           </v-list>
           <v-list :class="($vuetify.breakpoint.xs) ? 'mx-1' :'mx-4'" v-else-if="selected=='private'"  >
            <v-subheader>
              公開しているユーザー
        
             
              </v-subheader>
              <div class="d-flex mr-auto my-1" style="min-width:230px; width:60%;">
               <v-text-field
                label="ユーザーの追加(Email)"
                hint="例：sam@mail.com , bob@mail.com"
                v-model="addUserText"
                :error-messages="emailErrors"
                outlined
                dense
                
                class="ml-2"
                @input="$v.addUserText.$touch()"
                @blur="$v.addUserText.$touch()"
                @keydown.enter="addUser"
              ></v-text-field>
              <v-btn class="ml-1 white--text" elevation="1" outlined color="narekomu" :disabled="!checkEmail" @click="addUser" >追加</v-btn>
              </div>
            <v-list-item-group
              color="primary"
              style="max-height:200px; overflow-y: auto;"
            >
              <v-list-item
                v-for="(item, i) in sharedusers"
                :key="i"
              
              >
                <v-list-item-icon>
                  <v-icon> {{icons.account}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content  >
                  <v-list-item-title ><div  style="overflow-x:auto; ">{{item}}</div></v-list-item-title>
                </v-list-item-content>
                   <v-list-item-action>
                <v-list-item-action-text ></v-list-item-action-text>

                <v-btn elevation=0 icon @click="deleteUser(i)" ><v-icon
                  color="grey lighten-1"
                >{{icons.delete}}
                </v-icon>
                </v-btn>
              </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

         
        
          
        </v-card-text>
         
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn v-if="this.$store.state.user_model.userData.status >= '2'" 
            class="ma-2"
            outlined
            color="narekomu"   
            @click="save">変更を保存
          </v-btn>
          <v-btn class="ma-2"
            outlined
            color="#78909C"
            @click="close"> 
            <span v-if="this.$store.state.user_model.userData.status >= '2'" > キャンセル </span>
            <span v-else> 閉じる </span>
           </v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>

      <v-dialog 
        v-model="slide"
        max-width="400px" 
        width="90%"
        style="z-index:2001"
      >
        <CarouselTutorial
          type="publish"
          v-on:closeEmit="slide=false"
        />
      </v-dialog>

    </div>
      

</template> 

<script>
import axios from "axios"
const CarouselTutorial = () => import('@/components/CarouselTutorial.vue');
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mdiAccount, mdiDelete, mdiInformationOutline } from '@mdi/js';


export default {
  name: 'DialogPublish',
  props:["item","isTable","change"],
  mixins: [validationMixin],
  components:{
    CarouselTutorial
  },
  validations: {
    addUserText: { required},
  },
  data () {
    return {
      icons:{
        "account":mdiAccount,
        "informationOutline":mdiInformationOutline,
        "delete":mdiDelete
      },
      selected:this.item.publish,
      sharedusers:this.item.sharedUsers,
      loading:this.isTable,
      navi:this.item,
      addUserText:"",
      slide:false,
      // emailRules: [
      //   value => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //     return pattern.test(value) || 'Invalid e-mail.'
      //   },
      // ],
    }
  },

  watch: {
   
    change:{
      handler(){
        if(this.isTable){
          this.getData();
        }
      },
      deep: true,
      immediate: true

    },

  },

  computed:{

    emailErrors(){
      let errors = [];
      const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!this.$v.addUserText.$dirty) return errors
      let addUsersList = this.addUserText.replace(/\s+/g, "").split(",");

      if(this.addUserText == '' ){
        errors.push("メールアドレスを入力してください");
      }

      for(let i = 0; i < addUsersList.length; i++){
        let email = addUsersList[i];
        // console.log(email)
        if(!pattern.test(email)){
          errors.push("メールアドレスを入力してください");
        }
        if(this.sharedusers.includes(email)){
          errors.push(email+"はすでに登録されています");
        }
        if(email == this.$store.state.user_model.userData.userId){
          errors.push("自分のメールアドレスは登録できません");
        }

      }

      return errors;
    },

    checkEmail(){
      const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let addUsersList = this.addUserText.replace(/\s+/g, "").split(",");
      if(this.addUserText == '') return false;
      for(let i = 0; i < addUsersList.length; i++){
        let email = addUsersList[i];
        if( !pattern.test(email) || email == this.$store.state.user_model.userData.userId || this.sharedusers.includes(email)) return false;
  
      }


      return true;
    }

  },


  methods: {

      getData: async function () {
      // ナビの詳細データ取得
      this.loading = true;
      this.items = [];
      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.change}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json"},
        }).then((response) => {
          this.navi = response.data.results;
          this.selected = this.navi.publish;
          this.sharedusers = this.navi.sharedUsers;
          this.loading = false;
        })
        .catch(reason => {
          console.log(reason)
          this.loading = false;
          this.navi = {};
          this.selected = "unlisted";
          this.sharedusers = [];
      });


      },

    addUser(){
      if(this.checkEmail){
        let addUsers = this.addUserText.replace(/\s+/g, "").split(",");
        this.sharedusers = this.sharedusers.concat(addUsers);
        this.addUserText = "";
        this.$v.addUserText.$reset();

      }
  
    },

    deleteUser(index){
      this.sharedusers.splice(index, 1)
    },

    save(){
      if(this.isTable & this.$store.state.user_model.userData.status >= 2){
        this.loading = true;
        let data = {};
          data = {
            "Publish":this.selected,
            "SharedUsers":this.sharedusers,
          }
      
          axios({
            method: "PUT",
            url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.change}`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
            data:data,
          }).then(() => {
              this.loading = false;
              let sendItem = this.item;
              sendItem.publish = this.selected;
              this.$emit("savePublishEmit",sendItem)
          })
        
      }else{
        this.$emit("savePublishEmit",this.selected,this.sharedusers)
      }

    },

    close(){
      this.navi = {};
      this.$emit("closeEmit")
      this.$v.addUserText.$reset();
    }
 
  },
}

</script>
